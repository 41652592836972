import { ReactNode } from "react"
import { create } from "zustand"

interface AppDrawerStore {
  title: string
  description: string
  setTitle: (title: string) => void
  setDescription: (desc: string) => void
  content: ReactNode | null
  setContent: (node: ReactNode) => void
  openDrawer: (content: ReactNode) => void
  isOpen: boolean
  setIsOpen: (b: boolean) => void
  close: () => void
  onAnimEnd: (() => void) | undefined
  setOnAnimEnd: (f: () => void) => void
}

const appDrawerStore = create<AppDrawerStore>((set) => ({
  isOpen: false,
  content: null,
  title: "",
  description: "",
  setContent: (node: ReactNode) => set({ content: node }),
  setTitle: (title: string) => set({ title }),
  setDescription: (description: string) => set({ description }),
  setIsOpen: (b: boolean) => set({ isOpen: b }),
  openDrawer: (content: ReactNode) => set({ isOpen: true, content: content }),
  close: () => set({ isOpen: false }),
  setOnAnimEnd: (f: () => void) => set({ onAnimEnd: f }),
  onAnimEnd: undefined,
}))

export function useAppDrawer() {
  const onAnimEnd = appDrawerStore((state) => state.onAnimEnd)
  const setOnAnimEnd = appDrawerStore((state) => state.setOnAnimEnd)
  const isOpen = appDrawerStore((state) => state.isOpen)
  const setIsOpen = appDrawerStore((state) => state.setIsOpen)
  const content = appDrawerStore((state) => state.content)
  const setContent = appDrawerStore((state) => state.setContent)
  const close = appDrawerStore((state) => state.close)
  const openDrawer = appDrawerStore((state) => state.openDrawer)
  const title = appDrawerStore((state) => state.title)
  const setTitle = appDrawerStore((state) => state.setTitle)
  const description = appDrawerStore((state) => state.description)
  const setDescription = appDrawerStore((state) => state.setDescription)

  return {
    onAnimEnd,
    setOnAnimEnd,
    title,
    description,
    setTitle,
    setDescription,
    isOpen,
    content,
    setContent,
    setIsOpen,
    close,
    openDrawer,
  }
}
