import { useEffect, useState } from "react"

type Theme = "light" | "dark"

const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(() => {
    if (typeof window !== "undefined") {
      const storedTheme = localStorage.getItem("app-theme")
      return storedTheme === "dark" ? "dark" : "light" // Default to 'light' if not 'dark'
    }
    return "light" // Default theme for server-side rendering
  })

  useEffect(() => {
    localStorage.setItem("app-theme", theme)

    document.documentElement.className = theme
  }, [theme])

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"))
  }

  return {
    theme,
    setTheme,
    toggleTheme,
  }
}

export default useTheme
